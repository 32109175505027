/*
 * Copyright (C) 2012 PrimeBox (info@primebox.co.uk)
 * 
 * This work is licensed under the Creative Commons
 * Attribution 3.0 Unported License. To view a copy
 * of this license, visit
 * http://creativecommons.org/licenses/by/3.0/.
 * 
 * Documentation available at:
 * http://www.primebox.co.uk/projects/cookie-bar/
 * 
 * When using this software you use it at your own risk. We hold
 * no responsibility for any damage caused by using this plugin
 * or the documentation provided.
 */
 #cookie-bar{background:#111;height:auto;min-height:24px;line-height:24px;color:#eee;text-align:center;padding:3px 0;z-index:10000}#cookie-bar.fixed{position:fixed;top:0;left:0;width:100%}#cookie-bar.fixed.bottom{bottom:0;top:auto}#cookie-bar p{margin:0;padding:0}#cookie-bar a{color:#fff;display:inline-block;border-radius:3px;text-decoration:none;padding:0 6px;margin-left:8px}#cookie-bar .cb-enable{background:#070}#cookie-bar .cb-enable:hover{background:#090}#cookie-bar .cb-disable{background:#900}#cookie-bar .cb-disable:hover{background:#b00}#cookie-bar .cb-policy{background:#03b}#cookie-bar .cb-policy:hover{background:#05d}